.residence_proof_example{
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
}

.residence_proof_preview{
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
}