@import url('../common.css');

a:link { text-decoration: none; }

.password_reset_screen_foreground{
    display: block;
    padding-top: calc(100vh/10);

}
.password_reset_section{
    background-color: rgb(255, 255, 255);
    padding: 2rem;
    margin-bottom: 4rem;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;
}

.password_reset_instructions{
    font-family: base-font;
    font-size: 11pt;
    padding-top: 10px;
    padding-bottom: 20px;
  }

.password_reset_title_grid{
    display: grid;
    grid-template-columns: 40px 1fr;
}

/* Yes  */

.password_reset_title_image{
    width: 40px;
}

.password_reset_title_label{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 16pt;
    text-align: center;
}

.password_reset_image_title_grid{
    display: grid;
    grid-template-columns: 25px 1fr;
    margin-bottom: 15px;
}

.password_reset_contact_image{
    width: 20px;
}

.password_reset_contact_label{
    font-family: base-font;
    font-size: 13pt;
    color: rgb(66, 66, 66);
    line-height: 15px;
}

.password_reset_section_title{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 16pt;

}


/* Input related */

form .password_reset_input_box{
    height: 50px;
    width: 100%;
    position: relative;
    margin-top: 15px;
  }

.password_reset_input_box input{
    height: 100%;
    width: 100%;
    outline: none;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-left: 45px;
    font-size: 17px;
    transition: all 0.3s ease;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.password_reset_input_box input:focus{
    border-color: #845595;
}

.password_reset_input_box .password_reset_icon{
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    color: grey;
}

.password_reset_form_box{

    margin: 0 auto;
}


.password_reset_code_resend{
    text-align: center;
    color: rgb(30, 122, 215);
    font: font-base;
}

.password_reset_code_resend:hover{
    cursor: pointer;
}

/* =============================== */



@media only screen and (min-width: 600px) {

    .password_reset_screen_foreground{
        margin-left: calc((100vw - 500px) / 2);
        padding-top: calc(100vh/6);
    }

    .password_reset_section{
        border-radius: 15px;
        max-width: 500px;
    }

}