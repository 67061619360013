@import url('../../common.css');


.payment_cancel_button{
    background: #989898;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    box-sizing: border-box;
}

.payment_monthly_price{
    padding: 1rem 1rem 0rem 0rem;
    font-family: base-font;
    font-size: 20pt;
}

.payment_monthly_subtitle{
    padding: 0rem 1rem 1rem 0rem;
    font-family: base-font;
}

.payment_disclosure_note{
    padding: .75rem;
    background-color: rgb(245, 245, 245);
    border-color: black;
    border-style: solid;
    border-width: 1px;
    font-family: base-font;
    margin-bottom: 1rem;
    font-size: 9pt;
}

.payment_trial_box{
    padding: 20px;
    background-color: rgb(245, 245, 245);

    font-family: base-font;
}

.payment_trial_title{
    color: #845595;
    text-align: center;
    font-size: 16pt;
    padding-top: 10px;
    font-weight: bold;
}

.payment_trial_description{
    color: rgb(62, 62, 62);
    padding-top: 10px;
    font-size: 9pt;
    text-align: center;
}