@import url('../common.css');

a:link { text-decoration: none; }

.statement_review_screen_background{
    /* 10px padding + 70px logo */
    background-color: rgb(48, 48, 48);
}


.statement_review_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: 70px;
    margin-bottom: 6rem;
}

.statement_review_section{
    max-width: 800px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;

}



.statement_review_name_title{
    font-size: 16pt;
    color: #845595;
    font-family: base-font;
    text-align: left;
}





.statement_review_type_text{
    font-size: 8pt;
    color: rgb(65, 65, 65);
    font-family: base-font;
    text-align: left;
    padding-left: 10px;
}

.statement_review_within_section_title{
    font-size: 14pt;
    color: rgb(83, 83, 83);
    font-family: base-font;
    text-align: left;
    padding-bottom: 10px;
    padding-top: 10px;
}


.statement_review_top_grid{
    display: grid;
    grid-template-columns: 30px 1fr;
}

.statement_review_report_logo{
    height: 30px;

}

.statement_review_full_report_button{
    text-align: center;
    margin: 0 auto;
    font-family: base-font;
    color: rgb(56, 56, 56);
    border-radius: 10px;
    cursor: pointer;
    font-size: 12pt;
    padding: .25rem;
    margin-bottom: 1rem;
    background: rgb(244, 244, 244);

    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
}

.statement_review_no_doc{
    display: block;
    margin: 0 auto;
    height: 50px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.statement_review_text{
    font-family: base-font;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 12pt;
    color: rgb(66,66,66);
    text-align: center;
}


.statement_review_full_report_button:hover{
    background: rgb(95, 95, 95);
    color: white;
  }


  @media only screen and (min-width: 800px) {

    .statement_review_screen_background{
        /* 10px padding + 70px logo */
        background-color: rgb(0, 0, 0);
    
    }

    .statement_review_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;

    }

    .statement_review_section{
        padding: 1.5rem;
        padding-top: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        border-radius: 15px;
    }

    .official_statement_bubble {
        padding: 2rem;
      }
}