@import url('../common.css');

.signup_screen_foreground{
    display: block;
    margin-left: none;
    padding-top: calc(100vh/10);
    padding-bottom: 2rem;
}


.signup_inner_section{
    width: 100vw;
    background-color: rgb(255, 255, 255);
    padding: .5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;
    border-radius: 15px;
}

@media only screen and (min-width: 700px) {

    .signup_screen_foreground{
        margin-left: calc((100vw - 600px) / 2);
    }

    .signup_inner_section{
        min-width: none;
        width: 600px;
        padding: 2rem;
    }

}
