.general_info_overlay{
    position: fixed; /* Sit on top of the page content */

    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.856); /* Black background with opacity */
    z-index: 3;
}

.general_info_section{
    margin: auto;
    margin-top: 50%;
    width: 80%;
    border: 3px solid #845595;
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
}

.general_info_title_text{
    color: #845595;
    font-size: 16pt;
    margin-bottom: 1rem;
}

.general_info_details_text{
    color: rgb(59, 59, 59);
}

.general_info_close_button{
    text-align: center;
    padding: .75rem;
    margin-top: 2rem;
    background-color: #845595;
    cursor: pointer;
    color: white;
}

.general_info_close_button:hover{
    background-color: #845595;
}

/* Tabet */
@media only screen and (min-width: 600px) {
    .userErrorBox{
        width: 50%;
        margin-top: 20%;
    }
}


/* Computer */
@media only screen and (min-width: 1000px) {
    .userErrorBox{
        width: 50%;
        margin-top: 20%;
    }
}