@import url('../common.css');

.progress_bar_flex{
    display: flex;
    box-sizing: border-box;
    padding-top: .5rem;
    padding-bottom: .5rem;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
}

.progress_bar_flex > div {
    flex: 1;
  }

.progress_bar_element{
    padding-left: .55rem;
    padding-right: .55rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    font: base-font;
    margin: .1rem;
    border-radius: 5px;
    color: white;
    min-width: 100px;
    text-align: center;
    white-space: nowrap;
}

.progress_bar_element_hidden{
    padding-left: .1rem;
    padding-right: .1rem;
    padding-top: .1rem;
    padding-bottom: .1rem;
    font: base-font;
    margin: .1rem;
    border-radius: 5px;
    justify-content: center;
    align-content: center;
    background-color: gray;
    color: white;
    text-align: center;
    max-width: 25px;
}

.progress_bar_highlight{
    background-color: #845595;
}