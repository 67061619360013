@import url('../common.css');

.metric_description_section{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 80%;
    border-radius: 5px;
}

.metric_description_title{
    color: rgb(77, 77, 77);
    font-size: 18pt;
    font-family: base-font;
    text-align: center;
}

.metric_description_description{
    color: rgb(59, 59, 59);
    font-family: base-font;
    font-size: 11pt;
    text-align: center;
    padding: 1.75rem 0rem 1.75rem 0rem;
}

.metric_description_icon_center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    width: 40x;
}

.metric_description_image_bar{
    grid-template-columns: 3fr 1fr 1fr 3fr;
    display: grid;
}

.metric_description_count{
    text-align: center;
    font-size: 26pt;
    color: #845595;
    font-family: base-font;
}

@media only screen and (min-width: 600px) {
    .metric_description_section{
        width: 500px;
    }
}