@import url('../common.css');

a:link { text-decoration: none; }

.navigation_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: calc(50px + 1rem);
    padding-top: 10px;
    margin-left: none;
    padding-bottom: 5rem;
    min-height: 700px;
}

.navigation_section{
    max-width: 800px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 1.5rem;
    margin-bottom: .5rem;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;
}

.navigation_option{
    font-family: base-font;
    font-size: 12pt;
    height: 54px;
    line-height: 54px;
    vertical-align: middle;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(240, 240, 240);
    cursor: pointer;
    border-radius: 15px 15px 15px 15px;
    color: rgb(66,66,66);
    margin-bottom: 10px;
  }

.navigation_option_highlight{
background-color: #845595;
color: rgb(255, 255, 255);
}


@media only screen and (min-width: 800px) {


    .navigation_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;
    }

    .navigation_section{
        border-radius: 15px;
    }

}
