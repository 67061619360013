@import url('../common.css');


.success_submission_overlay{
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.856); /* Black background with opacity */
    z-index: 6;
}

.success_submission_section{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 2rem;
    width: 85%;
    border-radius: 5px;
}


.success_submission_description{
    color: rgb(60, 60, 60);
    font-family: base-font;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.success_submission_icon_center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 60px;
    width: 60px;
    margin-top: 1rem;
}

.success_submission_button{
    text-align: center;
    margin: 0 auto;
    font-family: base-font;
    color: rgb(60, 60, 60);
    border-radius: 10px;
    cursor: pointer;
    font-size: 12pt;
    padding: .25rem;
    margin-bottom: 1rem;
    background: rgb(244, 244, 244);

    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
}


@media only screen and (min-width: 600px) {
    .success_submission_section{
        width: 500px;

    }

}