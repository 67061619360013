@import url('../../common.css');

a:link { text-decoration: none; }

.unsubscribe_section{

    padding: 10px;
    background: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-sizing: border-box;
    max-width: 800px;
    margin-bottom: .5rem;
    animation: fadeInBox 1s;
    padding-top: 20px;
    padding-bottom: 20px;
}

.unsubscribe_are_you_sure{
    color: red;
    font-size: 16pt;
    font-family: base-font;
    padding-bottom: 10px;
}

.unsubscribe_note{
    color: rgb(67, 67, 67);
    font-size: 11pt;
    font-family: base-font;
    padding-bottom: 10px;
}

@media only screen and (min-width: 800px) {


    .unsubscribe_section{
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.75);
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
        margin-bottom: 1.5rem;
    }

}