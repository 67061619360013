
.logo_section{
    animation: animationLoad 10s ease-in;
    max-width: 50px;
    min-height: 65px;
}

.logo_stack_1{
    width: 14px;
    height: 14px;
    margin-top: 2px;
    background: rgb(138, 0, 192);
    border-radius: 5px;
    transform: perspective(10px) rotateY(1deg) rotate(45deg);
    animation: stackAnimation 2s alternate-reverse infinite ease-in-out;
    animation-delay: .5s;
}


.logo_stack_2{
    width: 14px;
    height: 14px;
    margin-top: 2px;
    background: rgb(201, 65, 255);
    border-radius: 5px;
    transform: perspective(10px) rotateY(1deg) rotate(45deg);
    animation: stackAnimation 2s alternate-reverse infinite ease-in-out;
    animation-delay: 1s;
}

.logo_stack_3{
    width: 14px;
    height: 14px;
    margin-top: 2px;
    background: rgb(220, 130, 255);
    border-radius: 5px;
    transform: perspective(10px) rotateY(1deg) rotate(45deg);
    animation: stackAnimation 2s alternate-reverse infinite ease-in-out;
    animation-delay: 1.5s;
}

.logo_stack_4{
    width: 14px;
    height: 14px;
    margin-top: 2px;
    background: rgb(236, 187, 253);
    border-radius: 5px;
    transform: perspective(10px) rotateY(1deg) rotate(45deg);
    animation: stackAnimation 2s alternate-reverse infinite ease-in-out;
    animation-delay: 2s;
    margin-bottom: -3rem;
}

.logoNameSection{
    display: block;
}

.logoName{
    padding-left: 1rem;
    padding-top: 1rem;
    margin-bottom: -.5rem;
    font-size: 28pt;
    color: rgb(126, 126, 126);
    font-family: Verdana, sans-serif;

}

@keyframes stackAnimation {
    from {margin-left: 30px;}
    to {margin-left: 2px;}
}

@keyframes animationLoad {
    from {opacity: 0%;}
    to {opacity: 100%;}
}