@import url('../common.css');

a:link { text-decoration: none; }

.metric_type_label{
    font-size: 9pt;
    line-height: 13pt;
    vertical-align: middle;
    font-family: base-font;
    text-align: center;
    color: white;
    border-radius: 10px 0px 0px 10px;
    border: 1px black solid
}

.metric_type_count{
    font-size: 10pt;
    font-family: base-font;
    text-align: right;
    color: rgb(0, 0, 0);

    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: black;
}

.metric_type_bar{
    grid-template-columns: 60px 2fr;
    display: grid;
    margin-bottom: 4px;
    cursor: pointer;
}

.RPV{
    background-color: rgb(78, 78, 78);
}

.UPV{
    background-color: rgb(78, 78, 78);
}

.RAV{
    background-color: rgb(78, 78, 78);
}

.UAV{
    background-color: rgb(78, 78, 78);

}

.RDV{
    background-color: rgb(78, 78, 78);
}

.UDV{
    background-color: rgb(78, 78, 78);

}


@media only screen and (min-width: 800px) {
    .metric_type_label{

        font-family: base-font;
        text-align: center;
        color: white;
        border-radius: 10px 0px 0px 10px;
        padding: 1px 1px 1px 0px;
    }
    
    .metric_type_count{

        font-family: base-font;
        text-align: right;
        color: rgb(0, 0, 0);
        background-color: rgb(255, 255, 255);
        border-radius: 0px 10px 0px 0px;
        padding: 1px 5px 1px 3px;
    }

}