@import url('../../common.css');

.successful_transaction_code_resend{
    text-align: center;
    color: rgb(30, 122, 215);
    font: font-base;
}

.successful_transaction_code_resend:hover{
    cursor: pointer;
}