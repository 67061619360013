@import url('../common.css');

a:link { text-decoration: none; }

.my_profile_screen_background{
    /* 10px padding + 70px logo */
    background-color: rgb(48, 48, 48);
}

.my_profile_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: calc(50px + 1rem);
    padding-top: 10px;
    padding-bottom: 5rem;
    min-height: 600px;
}

.my_profile_section{
    background: rgb(255, 255, 255);
    max-width: 800px;
    margin-bottom: .5rem;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;
}

.my_profile_page_title{
    color: rgb(255, 255, 255);
    font-size: 16pt;
    font-family: base-font;
    padding: 0rem 1rem .5rem 1rem;
}


.my_profile_section_title{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 16pt;

}

.my_profile_title_image{
    width: 30px;
}


.my_profile_title_grid{
    display: grid;
    grid-template-columns: 40px 1fr;

}

.my_profile_section_subtitle{
    font-family: base-font;
    color: rgb(125, 125, 125);
    font-size: 10pt;
    padding-bottom: 2px;

}

.my_profile_section_text{
    font-family: base-font;
    color: rgb(60, 60, 60);
    font-size: 12pt;
    padding-bottom: 10px;
}

.my_profile_user_image_center{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.my_profile_user_image{
    max-height: 120px;
    max-width: 120px;
    border-radius: 15px;

}


.my_profile_section_name{
    font-size: 28pt;
    color: #444444af;
    font-weight: bold;
    font-family: base-font;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
}


.my_profile_member_number{
    font-size: 11pt;
    color: #444444af;
    font-family: base-font;
    text-align: left;
    margin-bottom: 30px;
}

@media only screen and (min-width: 800px) {

    .my_profile_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;
    }

    .my_profile_page_title{
        padding-left: 0;
        color: rgb(60,60,60);
    }


    .my_profile_section{
        border-radius: 15px;
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
        margin-bottom: 1.5rem;
    }

    .my_profile_screen_background{
        /* 10px padding + 70px logo */
        background-color: rgb(0, 0, 0);
    }
    

}
