@font-face {
    font-family: base-font;
    src: url('./fonts/OpenSans-VariableFont_wdth\,wght.ttf');
}

@keyframes fadeInBox {
    from { 
        opacity: 0%;
    }
}

@keyframes fadeIn {
    from { 
        opacity: 0%;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
}




body {background-color: rgb(22, 22, 22);}

body::-webkit-scrollbar{
    display: none;
  }

.screen_background{
    z-index: 0;
    height: 100vh;
    display: block;
    background-color: #202020;
    height: 100vh;
    overflow-y: scroll;
}

.background_image{
    width: 100vw;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("./images/vStackBackground_600.png");
}


.common_overlay{
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.856); /* Black background with opacity */
    z-index: 10;
    animation: fadeIn .25s;
}

/*//////////////////////////////////////////////////////////*/
/* Logo                                                     */
/*//////////////////////////////////////////////////////////*/

.common_logo_section{
    display: grid;
    grid-template-columns: 50px 1fr;
}

.common_voterstack_name_section{
    display: grid;
    grid-template-rows: 2fr 1fr;
    max-height: 65px;
}

.common_voterstack_name{
    font-size: 26pt;
    font-weight: lighter;
    color: white;
    font-family: base-font;
    padding-left: .5rem;

}

.voterStackTitle{
    margin-top: 2rem;
    padding: 1rem;
    text-align: center;
    font-size: 32pt;
    color: rgb(138, 138, 138);
}

.hamburger_menu_icon{
    color: rgb(255, 255, 255);
    font-size: 35px;
    cursor: pointer;
    margin: auto;
    width: 50%;
}

.common_title{
    text-align: center;
    font-family: base-font;
    font-size: 22pt;
    color: rgb(77, 77, 77);
}

.common_input_text{
    margin-top: 1rem;
    text-align: left;
    font-family: base-font;
    font-size: 14pt;
    color: rgb(77, 77, 77);
    margin-bottom: .5rem;
}
.common_title_subtext{
    text-align: center;
    font-family: base-font;
    font-size: 18pt;
    color: rgb(77, 77, 77);
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.common_note{
    text-align: center;
    font-family: base-font;
    font-style: italic;
    font-size: 11pt;
    color: rgb(134, 133, 133);
    padding-top: .5rem;
    padding-bottom: .5rem;
}


.common_input{
    display:block;
    width: 100%;
    font-size: 14pt;
    color: rgb(141, 141, 141);
    font-family: base-font;
    padding: .5rem;
    border: none;
    outline: none;
    background-color: rgb(244, 244, 244);
    border-style: none;
    box-sizing:border-box;  /** add this **/
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    border-radius: 5px;
}

.common_icon_center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    width: 50px;
}


.common_button{
    border-radius: 5px;
    background-color: rgb(0, 0, 0);
    color: white;
    font-size: 12pt;
    padding: 10px;
    text-align: center;
    margin-top: 10px;
    font-family: base-font;
}

.common_button:hover{
    background-color: rgb(62, 62, 62);
    cursor: pointer;
}

.common_submit_button{
    background-color: #845595;
    color: white;
}

.common_submit_button:hover{
    background-color: rgb(81, 36, 117)
}

.common_cancel_button{
    background-color: rgb(188, 188, 188);
    color: white;
}

.common_cancel_button:hover{
    background-color: rgb(214, 61, 61);
}

.common_button_combination{
    display: grid;
    grid-template-columns: 1fr .1fr 1fr;
    box-sizing:border-box;  /** add this **/
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
}

.common_ack_button{
    background-color: rgb(255, 255, 255);
    color: white;
    margin-bottom: 0rem;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    font-family: base-font;
    color: rgb(60,60,60);
}

.common_ack_button:hover{
    background-color: rgb(60,60,60);
    color: white;
}


.common_divider{
    height: 2px;
    width: 100%;
    box-sizing: border-box;
    background-color: gray;
}

.common_section_title_2{
    font-size: 16pt;
    text-align: left;
    color: gray;
    padding-top: 1rem;
    padding-bottom: .25rem;
}

.circle {
    margin: 0 auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 10px;
    background: #845595;
    color: #ffffff;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: .25rem;
  }

  .loading_screen_overlay{
    z-index: 15;
    background-color: rgba(23, 23, 23, 0.821);
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    text-align: center;
    font-size: 30pt;
    height: 100vh;
    line-height: 100vh;
}

@media only screen and (min-width: 600px) {
    .background_image{
        background-image: url("./images/vStackBackground.png");
    }
}
