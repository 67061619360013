@import url('../../common.css');

.basic_information_subtitle_header{
    font-size: base-font;
    font-size: 18pt;
    color: #845595;
    text-align: left;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.basic_information_subtitle_subtext{
    font-size: base-font;
    font-style: italic;
    font-size: 12pt;
    color: rgb(167, 167, 167);
    text-align: left;
    margin-bottom: 2rem;
    margin-top: -1rem;
}

.basic_information_subtitle_subtext_two{
    font-size: base-font;
    font-style: italic;
    font-size: 12pt;
    color: rgb(167, 167, 167);
    text-align: left;
    margin-top: 1rem;
}

.basic_information_profile_photo{
    margin-top: 2rem;
    margin-bottom: -1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.basic_information_prepopulated{
    color: rgb(46, 46, 46);
}