@import url('../common.css');

a:link { text-decoration: none; }



.my_response_screen_foreground{
    /* 10px padding + 70px logo */
    margin-top: 70px;
    margin-bottom: 6rem;

}

.my_response_section{
    max-width: 800px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    box-sizing: border-box;
    -moz-box-sizing:border-box; /** add this **/
    -webkit-box-sizing:border-box; /** add this **/
    -ms-box-sizing:border-box; /** add this **/
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: fadeInBox 1s;
}

.my_response_section_title{
    font-size: 14pt;
    text-align: center;
    padding: 0rem 1rem 1rem 1rem;
    color: rgb(60, 60, 60);
    font-family: base-font;
    margin-top: 1rem;
}


.my_response_button_container{
    grid-template-columns: 1fr 1fr;
    display: grid;
    box-sizing: border-box;
    width: 100%;
}

.my_response_text_area{
    font-family: base-font;
    font-size: 12pt;
    padding: .5rem;
    width: 100%;
    height: 8rem;
    box-sizing: border-box;
    resize: none;
  }

.my_response_button{
    border-radius: 5px;
    cursor: pointer;
    font-size: 12pt;
    background: rgb(244, 244, 244);
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    cursor: pointer;
    padding: .5rem;
    text-align: center;
    font-size: 12pt;
    color: rgb(60, 60, 60);
    margin-bottom: .5rem;
}

.my_response_will_respond{
    background: rgb(13, 141, 45);
    color: white;
}

.my_response_will_not_respond{
    background: rgb(174, 19, 19);
    color: white;
}

.my_response_character_count{
    color: rgb(73, 73, 73);
    text-align: right;

  }

  .my_response_disclosure{
    color: rgb(73, 73, 73);
    text-align: left;
    padding-bottom: 1.5rem;

  }



.my_response_name_and_title_grid{
    display: grid;
    grid-template-columns: 50px 1fr;
}

.my_response_name_and_title_image{
    height: 50px;
    width: 50px;
    border-radius: 50px;
}

.my_response_name_and_title_container{
    padding-left: 10px;
    padding-right: 10px;
}

.my_response_name{
    font-family: base-font;
    font-size: 14pt;
    color: rgb(66, 66, 66);
}

.my_response_title{
    font-family: base-font;
    font-size: 10pt;
    color: rgb(121, 121, 121);
}


.my_response_posted_date{
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: base-font;
    font-size: 10pt;
    color: rgb(121, 121, 121);

}

.my_response_response{
    font-family: base-font;
    font-size: 12pt;
    color: rgb(66, 66, 66);
    padding: 15px;
    background-color: rgb(242, 242, 242);
}

.bounce2 {
    animation: bounce2 1s ease;
  }
  @keyframes bounce2 {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-30px);}
      60% {transform: translateY(-15px);}
  }

@media only screen and (min-width: 800px) {

    .my_response_screen_foreground{
        margin-left: calc((100vw - 800px) / 2);
        padding-top: 10px;

    }

    .my_response_section{
        padding: 1.5rem;
        padding-top: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        border-radius: 15px;
    }

}