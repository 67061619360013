form .input_box{
    height: 50px;
    width: 100%;
    position: relative;
    margin-top: 15px;
  }

.input_box input{
    height: 100%;
    width: 100%;
    outline: none;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding-left: 45px;
    font-size: 17px;
    transition: all 0.3s ease;
    box-sizing: border-box;
}

.input_box input:focus{
    border-color: #845595;
}

@import url('../common.css');

.input_box .icon{
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    color: grey;
}

.login_button{
    margin: auto;
    width: 100%;
    border-radius: 5px;

    color: rgb(60, 60, 60);
    font-size: 12pt;
    padding: 10px;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    font-family: base-font;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
}

.login_button:hover{
    background-color: rgb(60,60,60);
    color: white;
    cursor: pointer;
}

.forgot_password_button{
    color: rgb(110, 110, 110);
    text-align: center;
    cursor: pointer;
    font-size: 10pt;
    margin-top: 5px;
    margin-bottom: 15px;
}

.forgot_password_button:hover{
    color: #845595;
}

.form_box{

    margin: 0 auto;
}