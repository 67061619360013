@import url('../../common.css');

.review_information_user_entry{
    margin-top: .5rem;
    text-align: left;
    font-family: base-font;
    font-size: 12pt;
    color: rgb(158, 158, 158);
    margin-bottom: .5rem;
}

.review_information_profile_photo{
    margin-top: 2rem;
    margin-bottom: -1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.review_information_proof_photo{
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: block;
    width: 250px;
}

.review_information_subtitle_header{
    font-size: base-font;
    font-size: 18pt;
    color: rgb(201, 53, 201);
    text-align: left;
    margin-bottom: 1rem;
    margin-top: 2rem;
}